const analytics = {
    namespaced: true,
    state: {
        period: {
            from: null,
            to: null,
        },
        loading: {
            mostViewedArticles: false,
            authorizationsPerPeriod: false
        },
        mostViewedArticles: [],
        popularAuthors: [],
        articlesPerPeriod: [],
        authorizationsPerPeriod: []
    },
    getters: {
        loading: (state) => state.loading,
        period: (state) => state.period,
        mostViewedArticles: (state) => state.mostViewedArticles,
        mostViewedArticlesLoading: (state) => state.mostViewedArticlesLoading,
        getPopularAuthors: (state) => state.popularAuthors,
        getArticlesPerPeriod: (state) => state.articlesPerPeriod,
        getAuthorizationsPerPeriod: (state) => state.authorizationsPerPeriod
    },
    mutations: {
        SET_LOADING(state, loadingEntity) {
            state.loading[loadingEntity.type] = loadingEntity.value;
        },
        SET_MOST_VIEWED_ARTICLES(state, mostViewedArticles) {
            state.mostViewedArticles = mostViewedArticles;
        },
        SET_POPULAR_AUTHORS(state, popularAuthors) {
            state.popularAuthors = popularAuthors;
        },
        SET_ARTICLES_PER_PERIOD(state, articlesPerPeriod) {
            state.articlesPerPeriod = articlesPerPeriod;
        },
        SET_AUTHORIZATIONS_PER_PERIOD(state, authorizationsPerPeriod) {
            state.authorizationsPerPeriod = authorizationsPerPeriod;
        }
    },
    actions: {
        loadMostViewedArticles({ commit }, data) {
            commit("SET_LOADING", {type: 'mostViewedArticles', value: true});
            this.$app.$api.analytics.
            mostViewedArticles(data).then((response) => {
                commit("SET_MOST_VIEWED_ARTICLES", response.data.data);
                commit("SET_LOADING", {type: 'mostViewedArticles', value: false});
            });
        },
        loadMostPopularAuthors({ commit }, data) {
            return this.$app.$api.analytics.mostPopularAuthors({
                period: JSON.stringify(data.period), 
                limit: data.limit
            }).then((response) => {
                let popularAuthors = [];
                Object.entries(response.data).forEach(([key, value]) => {
                    popularAuthors.push(value);
                });
                commit("SET_POPULAR_AUTHORS", popularAuthors, false);
            });
        },
        loadArticlesPerPeriod({ commit }, data){
            return this.$app.$api.analytics.articlesPerPeriod({
                period: JSON.stringify(data.period)
            }).then((response) => {
                let articlesPerPeriod = [];
                Object.entries(response.data).forEach(([key, value]) => {
                    articlesPerPeriod.push(value);
                });
                commit("SET_ARTICLES_PER_PERIOD", articlesPerPeriod, false);
            });
        },
        loadAuthorizationsPerPeriod({ commit }, data){
            commit("SET_LOADING", {type: 'authorizationsPerPeriod', value: true});
            this.$app.$api.analytics.
            authorizationsPerPeriod(data).then((response) => {
                commit("SET_AUTHORIZATIONS_PER_PERIOD", response.data);
                commit("SET_LOADING", {type: 'authorizationsPerPeriod', value: false});
            });
        }
    }
};

export default analytics;