import Vue from "vue";

import formatDate from "date-fns/format";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en";
import ruLocale from "date-fns/locale/ru";

const lang = document.documentElement.lang;
let locale;

switch (lang) {
  case "en":
    locale = enLocale;
    break;
  case "de":
    locale = deLocale;
    break;
  default:
    locale = ruLocale;
}

Vue.filter("formatArticleDate", function (value) {
  if (value) {
    return formatDate(value, "D MMMM, H:mm", { locale: locale });
  }
});

Vue.filter("formatHistoryItemDate", function (value) {
  if (value) {
    return formatDate(value, "D MMMM, H:mm", { locale: locale });
  }
});

Vue.filter("formatCommentDay", function (value) {
  if (value) {
    return formatDate(value, "DD.MM.YYYY", { locale: locale });
  }
});

Vue.filter("formatCommentTime", function (value) {
  if (value) {
    return formatDate(value, "HH:mm", { locale: locale });
  }
});

Vue.filter("objectLength", function (value) {
  if (value && typeof value === "object") return Object.keys(value).length;
  else return 0;
});

Vue.filter("truncate", function (text, length, clamp) {
  text = text || "";
  clamp = clamp || "...";
  length = length || 200;

  text = text.replace(/<\/?[^>]+>/gi, "");

  if (text.length <= length) return text;

  let tcText = text.slice(0, length - clamp.length);
  let last = tcText.length - 1;

  while (last > 0 && tcText[last] !== " " && tcText[last] !== clamp[0])
    last -= 1;

  // Fix for case when text dont have any `space`
  last = last || length - clamp.length;

  tcText = tcText.slice(0, last);

  return tcText + clamp;
});
