const helpers = {
  calc_scope_key: ({ scope }) => [scope.type, scope.id].join("-"),
  calc_keys: ({ scope, role_id, recursive }, ability_key) => {
    return {
      scope:   helpers.calc_scope_key({ scope }),
      role:    [role_id, recursive * 1].join("-"),
      ability: ability_key,
    };
  },
  is_equals: {
    role: (a, b) => {
      return (
        a.role === b.role &&
        a.role_entity.type === b.role_entity.type &&
        a.role_entity.id === b.role_entity.id &&
        a.recursive === b.recursive
      );
    },
    ability: (a, b) => {
      return (
        a.ability === b.ability &&
        a.forbidden === b.forbidden
      );
    },
  },
  implode_keys: ({ scope, role, ability }) => [scope, role, ability].join("|"),
  explode_keys: (key) => {
    const parts = key.split("|");
    return {
      scope: parts[0],
      role: parts[1],
      ability: parts[2],
    };
  },
};

const access = {
  namespaced: true,
  state: {
    current: {
      id: null,
      type: null,
      loading: false,
    },
    rights: {},
    change: {
      define: {},
      forbid: {},
    },
    counters: {
      role: 0,
      ability: 0,
    },
  },
  getters: {
    loaded({ current }) {
      return !current.loading;
    },
    is_current({ current }) {
      return (type, id) => {
        return current.type === type && current.id === id;
      };
    },
    rights: (state) => state.rights,
    get_permission: (state) => ({ scope, role, ability }) => {
        const change_key = helpers.implode_keys({ scope, role, ability });
        return {
          ...(state.rights[scope][role] || {}),
          ...(state.rights[scope][role].abilities[ability] || {}),
          abilities: null,
          role_id: (state.rights[scope][role].role_id + "")[0] !== "n"
            ? state.rights[scope][role].role_id : null,
          is_define: state.change["define"][change_key] || false,
          is_forbid: state.change["forbid"][change_key] || false,
        };
      },
    calc_keys: () => helpers.calc_keys,
    find_role_id: (state) => (data) => {
      const scope_key = helpers.calc_scope_key(data);

      if (state.rights[scope_key] === undefined) {
        return null;
      }

      Object.keys(state.rights[scope_key]).forEach((role_key) => {
        if (helpers.is_equals.role(data, state.rights[scope_key][role_key])) {
          return role_key;
        }
      });

      return null;
    },
    find_ability_key: (state) => (data) => {
      const keys = helpers.calc_keys(data, null);

      Object.keys(state.rights[keys.scope][keys.role].abilities).forEach(
        (ability_key) => {
          if (
            helpers.is_equals.ability(
              data,
              state.rights[keys.scope][keys.role].abilities[ability_key]
            )
          ) {
            return ability_key;
          }
        }
      );

      return null;
    },
    get_permission_status: (state, getters) => (keys) => {
      const permission = getters.get_permission(keys);
      if (permission.global && permission.ability_id !== undefined) {
        return "global";
      }

      if (permission.is_define) {
        return "define";
      }
      if (permission.is_forbid) {
        return "forbid";
      }

      return "active";
    },
  },
  mutations: {
    SET_CURRENT_ENTITY(state, { type, id }) {
      state.current.type = type;
      state.current.id = id;
    },
    SET_CURRENT_STATUS(state, loading) {
      state.current.loading = loading;
    },
    SET_CURRENT_RULES(state, data) {
      state.rights = data;
      state.counters = {
        role: 0,
        ability: 0,
      };
      state.change = {
        define: {},
        forbid: {},
      };
    },
    SET_PERMISSION_STATUS(state, { status, keys, value }) {
      const change_key = helpers.implode_keys(keys);
      this.$app.$set(state.change[status], change_key, value);
    },
    INCREMENT_COUNTER(state, counter) {
      state.counters[counter]++;
    },
    SET_NEW_PERMISSION(state, { data, keys: { scope, role, ability } }) {
      if (state.rights[scope] === undefined) {
        this.$app.$set(state.rights, scope, {});
      }
      if (state.rights[scope][role] === undefined) {
        this.$app.$set(state.rights[scope], role, {
          ...data,
          abilities: {},
        });
      }
      this.$app.$set(state.rights[scope][role].abilities, ability, {
        ability: data.ability,
        forbidden: data.forbidden,
      });
    },
    REMOVE_NEW_PERMISSION(state, { scope, role, ability }) {
      this.$app.$delete(state.rights[scope][role].abilities, ability);
      if (Object.keys(state.rights[scope][role].abilities).length === 0) {
        this.$app.$delete(state.rights[scope], role);
      }
      if (Object.keys(state.rights[scope]).length === 0) {
        this.$app.$delete(state.rights, scope);
      }
    },
  },
  actions: {
    drop_current({ commit }) {
      commit("SET_CURRENT_ENTITY", { type: null, id: null });
      commit("SET_CURRENT_STATUS", false);
    },
    forbid({ commit }, { keys, value = true }) {
      commit("SET_PERMISSION_STATUS", { status: "forbid", keys, value });
    },
    load({ commit, getters }, { type, id }) {
      commit("SET_CURRENT_ENTITY", { type, id });
      commit("SET_CURRENT_RULES", []);
      commit("SET_CURRENT_STATUS", true);
      this.$app.$api.access
        .get({ type, id })
        .then(({ data }) => {
          if (!getters["is_current"](type, id)) return false;
          commit("SET_CURRENT_RULES", data);
        })
        .finally(() => {
          commit("SET_CURRENT_STATUS", false);
        });
    },
    add_new({ state, commit, getters }, data) {
      /* Check if we can\need add permission */
      data.role_id = getters.find_role_id(data);
      if (data.role_id === null) {
        data.role_id = "n" + state.counters.role;
        commit("INCREMENT_COUNTER", "role");
      } else if (getters.find_ability_key(data)) {
        return;
      }

      /* Calc last keys */
      const ability_key = "n" + state.counters.ability;
      commit("INCREMENT_COUNTER", "ability");
      const keys = helpers.calc_keys(data, ability_key);
      /* Add data and status */
      commit("SET_NEW_PERMISSION", { data, keys });
      commit("SET_PERMISSION_STATUS", { status: "define", keys, value: true });
    },
    remove_new({ commit }, keys) {
      commit("REMOVE_NEW_PERMISSION", keys);
      commit("SET_PERMISSION_STATUS", { status: "define", keys, value: false });
    },

    save({ commit, state, getters }) {
      const query = {
        // entity: {
        id: state.current.id,
        type: state.current.type,
        // },
        define: [],
        forbid: [],
      };

      Object.keys(state.change).forEach((action) => {
        Object.keys(state.change[action]).forEach((change_key) => {
          if (state.change[action][change_key]) {
            const keys = helpers.explode_keys(change_key);
            query[action].push(getters.get_permission(keys));
          }
        });
      });

      commit("SET_CURRENT_STATUS", true);
      this.$app.$api.access
        .set(query)
        .then(({ data }) => {
          if (!getters["is_current"](query.type, query.id)) return false;
          commit("SET_CURRENT_RULES", data);
        })
        .finally(() => {
          commit("SET_CURRENT_STATUS", false);
        });
    },
  },
};

export default access;
