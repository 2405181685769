export const AG_GRID_LOCALE_RU = {
  sum: "Сумма",
  min: "Минимум",
  max: "Максимум",
  none: "Пусто",
  count: "Количество",
  avg: "Среднее значение",
  average: "Среднее значение",
  filteredRows: "Отфильтрованные",
  selectedRows: "Выделенные",
  totalRows: "Всего строк",
  totalAndFilteredRows: "Строк",
  more: "ещё",
  to: "к(до)",
  of: "из",
  page: "Страница",
  nextPage: "Следующая",
  lastPage: "Последняя",
  firstPage: "Первая",
  previousPage: "Предыдущая",
  next: "Следующая",
  last: "Последняя",
  first: "Первая",
  previous: "Предыдущая",

  // Set Filter
  selectAll: "(Выделить все)",
  selectAllSearchResults: "(Выбрать все найденные результаты)",
  searchOoo: "Поиск...",
  blanks: "(Ничего не найдено)",
  noMatches: "Нет совпадений",

  // Number Filter & Text Filter
  filterOoo: "Фильтровать...",
  equals: "Равно",
  notEqual: "Не равно",
  empty: "Выбрать один",

  // Number Filter
  lessThan: "Меньше чем",
  greaterThan: "Больше чем",
  lessThanOrEqual: "Меньше или равно",
  greaterThanOrEqual: "Больше или равно",
  inRange: "В промежутке",
  inRangeStart: "с",
  inRangeEnd: "до",

  // Text Filter
  contains: "Содержит",
  notContains: "Не содержит",
  startsWith: "Начинается с",
  endsWith: "Заканчивается",

  // Date Filter
  dateFormatOoo: "гггг-мм-дд",

  // Filter Conditions
  andCondition: "И",
  orCondition: "ИЛИ",

  // Filter Buttons
  applyFilter: "Применить",
  resetFilter: "Сбросить",
  clearFilter: "Очистить",
  cancelFilter: "Отменить",

  // Filter Titles
  textFilter: "Фильтр по тексту",
  numberFilter: "Фильтр по числам",
  dateFilter: "Фильтр по дате",
  setFilter: "Установить фильтр",

  // Side Bar
  columns: "Столбцы",
  filters: "Фильтры",
  rowGroupColumns: "Столбцы группировки по строкам",

  // columns tool panel
  pivotMode: "Pivot Mode",
  groups: "Столбцы группировки по строкам",
  rowGroupColumnsEmptyMessage: "Перетащите сюда для группировки по строкам",
  valueColumns: "Столбцы со значениями",
  values: "Значения",
  valueColumnsEmptyMessage: "Перетащите сюда для агрегации",
  pivots: "Заголовки столбцов",
  pivotColumnsEmptyMessage: "Перетащите сюда, чтобы задать заголовки столбам",
  toolPanelButton: "Панель инструментов",
  // Header of the Default Group Column
  group: "Группа",

  // Other
  loadingOoo: "Загрузка...",
  noRowsToShow: "Нет данных",
  enabled: "Включено",

  // Menu
  pinColumn: "Закрепить колонку",
  pinLeft: "Закрепить слева <<",
  pinRight: "Закрепить справа >>",
  noPin: "Не закреплять <>",
  valueAggregation: "Агрегация по значению",
  autosizeThiscolumn: "Автоматически задавать размер этой колонки",
  autosizeAllColumns: "Автоматически задавать размер всем колонкам",
  groupBy: "Группировать по",
  ungroupBy: "Разгруппировать по",
  resetColumns: "Сбросить столбцы",
  expandAll: "Развернуть всё",
  collapseAll: "Свернуть всё",
  copy: "Копировать",
  ctrlC: "Ctrl+C",
  copyWithHeaders: "Копировать с заголовком",
  paste: "Вставить",
  ctrlV: "Ctrl+V",
  export: "Экспортировать",
  csvExport: "Экспортировать в CSV",
  excelExport: "Экспортировать в excel",

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "Pivot Chart & Pivot Mode",
  pivotChart: "Pivot Chart",
  chartRange: "Chart Range",

  columnChart: "Column",
  groupedColumn: "Grouped",
  stackedColumn: "Stacked",
  normalizedColumn: "100% Stacked",

  barChart: "Bar",
  groupedBar: "Grouped",
  stackedBar: "Stacked",
  normalizedBar: "100% Stacked",

  pieChart: "Pie",
  pie: "Pie",
  doughnut: "Doughnut",

  line: "Line",

  xyChart: "X Y (Scatter)",
  scatter: "Scatter",
  bubble: "Bubble",

  areaChart: "Area",
  area: "Area",
  stackedArea: "Stacked",
  normalizedArea: "100% Stacked",

  histogramChart: "Histogram",

  // Charts
  pivotChartTitle: "Pivot Chart",
  rangeChartTitle: "Range Chart",
  settings: "Settings",
  data: "Data",
  format: "Format",
  categories: "Categories",
  defaultCategory: "(None)",
  series: "Series",
  xyValues: "X Y Values",
  paired: "Paired Mode",
  axis: "Axis",
  navigator: "Navigator",
  color: "Color",
  thickness: "Thickness",
  xType: "X Type",
  automatic: "Automatic",
  category: "Category",
  number: "Number",
  time: "Time",
  xRotation: "X Rotation",
  yRotation: "Y Rotation",
  ticks: "Ticks",
  width: "Width",
  height: "Height",
  length: "Length",
  padding: "Padding",
  spacing: "Spacing",
  chart: "Chart",
  title: "Title",
  titlePlaceholder: "Chart title - double click to edit",
  background: "Background",
  font: "Font",
  top: "Top",
  right: "Right",
  bottom: "Bottom",
  left: "Left",
  labels: "Labels",
  size: "Size",
  minSize: "Minimum Size",
  maxSize: "Maximum Size",
  legend: "Legend",
  position: "Position",
  markerSize: "Marker Size",
  markerStroke: "Marker Stroke",
  markerPadding: "Marker Padding",
  itemSpacing: "Item Spacing",
  itemPaddingX: "Item Padding X",
  itemPaddingY: "Item Padding Y",
  layoutHorizontalSpacing: "Horizontal Spacing",
  layoutVerticalSpacing: "Vertical Spacing",
  strokeWidth: "Stroke Width",
  offset: "Offset",
  offsets: "Offsets",
  tooltips: "Tooltips",
  callout: "Callout",
  markers: "Markers",
  shadow: "Shadow",
  blur: "Blur",
  xOffset: "X Offset",
  yOffset: "Y Offset",
  lineWidth: "Line Width",
  normal: "Normal",
  bold: "Bold",
  italic: "Italic",
  boldItalic: "Bold Italic",
  predefined: "Predefined",
  fillOpacity: "Fill Opacity",
  strokeOpacity: "Line Opacity",
  histogramBinCount: "Bin count",
  columnGroup: "Column",
  barGroup: "Bar",
  pieGroup: "Pie",
  lineGroup: "Line",
  scatterGroup: "X Y (Scatter)",
  areaGroup: "Area",
  histogramGroup: "Histogram",
  groupedColumnTooltip: "Grouped",
  stackedColumnTooltip: "Stacked",
  normalizedColumnTooltip: "100% Stacked",
  groupedBarTooltip: "Grouped",
  stackedBarTooltip: "Stacked",
  normalizedBarTooltip: "100% Stacked",
  pieTooltip: "Pie",
  doughnutTooltip: "Doughnut",
  lineTooltip: "Line",
  groupedAreaTooltip: "Area",
  stackedAreaTooltip: "Stacked",
  normalizedAreaTooltip: "100% Stacked",
  scatterTooltip: "Scatter",
  bubbleTooltip: "Bubble",
  histogramTooltip: "Histogram",
  noDataToChart: "No data available to be charted.",
  pivotChartRequiresPivotMode: "Pivot Chart requires Pivot Mode enabled.",
  chartSettingsToolbarTooltip: "Menu",
  chartLinkToolbarTooltip: "Linked to Grid",
  chartUnlinkToolbarTooltip: "Unlinked from Grid",
  chartDownloadToolbarTooltip: "Download Chart",

  // ARIA
  ariaHidden: "hidden",
  ariaVisible: "visible",
  ariaChecked: "checked",
  ariaUnchecked: "unchecked",
  ariaIndeterminate: "indeterminate",
  ariaColumnSelectAll: "Toggle Select All Columns",
  ariaInputEditor: "Input Editor",
  ariaDateFilterInput: "Date Filter Input",
  ariaFilterInput: "Filter Input",
  ariaFilterColumnsInput: "Filter Columns Input",
  ariaFilterValue: "Filter Value",
  ariaFilterFromValue: "Filter from value",
  ariaFilterToValue: "Filter to value",
  ariaFilteringOperator: "Filtering Operator",
  ariaColumnToggleVisibility: "column toggle visibility",
  ariaColumnGroupToggleVisibility: "column group toggle visibility",
  ariaRowSelect: "Press SPACE to select this row",
  ariaRowDeselect: "Press SPACE to deselect this row",
  ariaRowToggleSelection: "Press Space to toggle row selection",
  ariaRowSelectAll: "Press Space to toggle all rows selection",
  ariaSearch: "Search",
  ariaSearchFilterValues: "Search filter values",
};
