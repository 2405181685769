const trashed = {
  namespaced: true,
  state: {
    trashedList: [],
    loaded: false,
  },
  getters: {
    trashedList: (state) => state.trashedList,
    loaded: (state) => state.loaded,
  },
  mutations: {
    SET_TRASHED_LOADED(state, loaded) {
      state.loaded = loaded;
    },
  },
  actions: {
    loadTrashed(context, { data }) {
      return new Promise((resolve, reject) => {
        this.$app.$api.trashed
          .get(data)
          .then((response) => {
            context.commit("SET_TRASHED_LOADED", true);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    restore(context, { data }) {
      return new Promise((resolve, reject) => {
        this.$app.$api.trashed
          .restore(data)
          .then((response) => {
            context.commit("SET_TRASHED_LOADED", false);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
export default trashed;
