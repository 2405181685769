const main_page = {
    namespaced: true,
    state: {
        topSections: [],
        topSectionLoaded: false,
        mostLikedArticles: [],
        mostLikedArticlesLoaded: false,
        lastSeenArticles: [],
        lastSeenArticlesLoaded: false,
        lastUpdateArticles: [],
        lastUpdateArticlesLoaded: false,
    },
    getters: {
        topSections: state => state.topSections,
        mostLikedArticles: state => state.mostLikedArticles,
        lastSeenArticles: state => state.lastSeenArticles,
        lastUpdateArticles: state => state.lastUpdateArticles,
        lastSeenArticlesLoaded: state => state.lastSeenArticlesLoaded,
        mostLikedArticlesLoaded: state => state.mostLikedArticlesLoaded,
        topSectionLoaded: state => state.topSectionLoaded,
        lastUpdateArticlesLoaded: state => state.lastUpdateArticlesLoaded,
    },
    mutations: {
        SET_TOP_SECTIONS_LOADED(state, status) {
            state.topSectionLoaded = status;
        },
        SET_TOP_SECTIONS(state, sections) {
            state.topSections = sections;
        },
        SET_MOST_LIKED_ARTICLES(state, articles) {
            state.mostLikedArticles = articles;
        },
        SET_LAST_SEEN_ARTICLES(state, articles) {
            state.lastSeenArticles = articles;
        },
        SET_LAST_UPDATE_ARTICLES(state, articles) {
            state.lastUpdateArticles = articles;
        },
        SET_ARTICLE_REACTION(state, { id, status}) {
            state.mostLikedArticles.forEach(article => {
                if(article.id === id){
                    article.is_liked = status;
                    if(status) {
                        article.likes++;
                    }
                    else {
                        article.likes--;
                    }
                }
            });
            state.lastSeenArticles.forEach(article => {
                if(article.id === id && !article.is_full){
                    article.is_liked = status;
                    if(status) {
                        article.likes++;
                    }
                    else {
                        article.likes--;
                    }
                }
            });
            state.lastUpdateArticles.forEach(article => {
                if(article.id === id){
                    article.is_liked = status;
                    if(status) {
                        article.likes++;
                    }
                    else {
                        article.likes--;
                    }
                }
            });
        },
        ADD_ARTICLE_TO_LAST_SEEN(state, article) {
            article.comments_count = article.comments ? Object.keys(article.comments).length : 0;
            state.lastSeenArticles = state.lastSeenArticles.filter(item => item.id !== article.id);
            state.lastSeenArticles.unshift(article);
        }
    },
    actions: {
        loadTopSections(context){
            if(context.state.topSectionLoaded) return;
            const request = this.$app.$api.section.getTop();
            request.then((response) => {
                    context.commit("SET_TOP_SECTIONS", response.data.data);
                }).finally(() => {
                    context.commit("SET_TOP_SECTIONS_LOADED", true);
                });
        },
        loadMostLikedArticles(context) {
            if(context.state.mostLikedArticlesLoaded) return;
            const request = this.$app.$api.article.mostLiked();
            request.then((response) => {
                context.commit("SET_MOST_LIKED_ARTICLES", response.data.data);
            }).finally(() => {
                context.state.mostLikedArticlesLoaded = true;
            });
        },
        loadLastSeenArticles(context) {
            if(context.state.lastSeenArticlesLoaded) return;
            const request = this.$app.$api.article.lastSeen();
            request.then((response) => {
                context.commit("SET_LAST_SEEN_ARTICLES", response.data.data);
            }).finally(() => {
                context.state.lastSeenArticlesLoaded = true;
            });
        },
        lastUpdateArticles(context) {
            const request = this.$app.$api.article.lastUpdate();
            request.then((response) => {
                context.commit("SET_LAST_UPDATE_ARTICLES", response.data.data);
            }).finally(() => {
                context.state.lastUpdateArticlesLoaded = true;
            });
        },
    }
};
export default main_page;
