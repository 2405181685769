const errors = {
  namespaced: true,
  state: {
    status401: false,
    status403: false,
    status404: false,
    defaultResponseError: false,
    forbiddenResponseError: false,
  },
  getters: {
    status401: (state) => state.status401,
    status404: (state) => state.status404,
    defaultResponseError: (state) => state.defaultResponseError,
    forbiddenResponseError: (state) => state.forbiddenResponseError,
  },
  mutations: {
    SET_STATUS_401(state, value) {
      state.status401 = value;
    },
    SET_STATUS_404(state, value) {
      state.status404 = value;
    },
    SET_DEFAULT_ERROR(state, value) {
      state.defaultResponseError = value;
    },
    SET_FORBIDDEN_ERROR(state, value) {
      state.forbiddenResponseError = value;
    },
  },
  actions: {
    setStatus401({ commit, state }, value) {
      commit("SET_STATUS_401", value);
    },
    setStatus404({ commit, state }, value) {
      commit("SET_STATUS_404", value);
    },
    setDefault({ commit, state }, value) {
      commit("SET_DEFAULT_ERROR", value);
    },
    setForbidden({ commit, state }, value) {
      commit("SET_FORBIDDEN_ERROR", value);
    },
  },
};

export default errors;
