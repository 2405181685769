import { AG_GRID_LOCALE_RU } from "../locales/ag-grid/ru";
import { AG_GRID_LOCALE_EN } from "../locales/ag-grid/en";

const agGrid = {
  namespaced: true,
  state: {
    locales: {
      ru: AG_GRID_LOCALE_RU,
      en: AG_GRID_LOCALE_EN,
      de: AG_GRID_LOCALE_EN,
    },
  },
  getters: {
    getAgGridLocales: (state) => (locale) => state.locales[locale],
  },
  actions: {
    init: () => {
      return import(
        /* webpackChunkName: "ag-grid" */
        /* webpackExports: ["LicenseManager"] */
        "@ag-grid-enterprise/core"
      ).then(({ LicenseManager }) => {
        /* Ag-Grid license key */
        LicenseManager.setLicenseKey(process.env.VUE_APP_AG_GRID_LICENSE_KEY);
      });
    },
  },
};

export default agGrid;
