import Vue from "vue";
const loading = {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    loading: (state) => state.loading,
  },
  mutations: {
    STARTED(state) {
      Vue.set(state, "loading", true);
    },
    FINISHED(state) {
      Vue.set(state, "loading", false);
    },
  },
};

export default loading;
