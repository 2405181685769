import Vue from "vue";

import { USER_SET } from "@hihub/vue-hihub-ui-core/src/core/services/store/users.module";

const users_kb = {
  namespaced: true,
  state: {
    search: {},
    counters: {
      search: {},
    },
    usersList: {},
  },
  getters: {
    IsSearched: (state) => (block) =>
      state.search[block] !== undefined && state.search[block] !== null,
    GetSearched: (state) => (block) => {
      return state.search[block] !== undefined && state.search[block] !== null
        ? state.search[block]
        : [];
    },
    GetSearchCounter: (state) => (block) => {
      return state.counters.search[block] || 0;
    },
  },
  mutations: {
    SET_SEARCH_RESULT(state, payload) {
      if (payload.block !== undefined) {
        if (payload.data === undefined || payload.data === null) {
          Vue.delete(state.search, payload.block);
        } else {
          Vue.set(
            state.search,
            payload.block,
            Object.keys(payload.data).map((index) => {
              return payload.data[index].id;
            })
          );
        }
      }
    },
    INCREASE_SEARCH_COUNTER(state, { block }) {
      let current = state.counters.search[block] || 0;
      Vue.set(state.counters.search, block, current + 1);
    },
  },
  actions: {
    async search(context, payload) {
      if (payload.block !== undefined) {
        let data = { block: payload.block, data: {} };

        context.commit("SET_SEARCH_RESULT", data);
        context.commit("INCREASE_SEARCH_COUNTER", data);
      } else {
        return null;
      }
      const current_counter = context.state.counters.search[payload.block];

      if (payload.query === undefined || payload.query.length < 2) return null;

      return this.$app.$api.users
        .search({ query: payload.query, scope: payload.scope, per_page: 12 })
        .then(({ data: { data } }) => {
          Object.values(data).forEach(function (item) {
            context.commit("users/" + USER_SET, item, { root: true });
          });
          if (
            context.getters.GetSearchCounter(payload.block) === current_counter
          )
            context.commit("SET_SEARCH_RESULT", {
              block: payload.block,
              data: data,
            });
          return data;
        });
    },
  },
};

export default users_kb;
