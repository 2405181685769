export default {
  department: "Abteilung",
  position: "Position",
  competence: "Kompetenz",
  employe: "Mitarbeiter",
  add_familization_users: "Teilnehmer hinzufügen",
  select_all: "Alle auswählen",
  familization_users: "Mitarbeiter in der Zuordnung",
  familization_search_input: "Geben Sie den Zielnamen ein",
  user_search_input: "Geben Sie den Jobtitel ein",
  departments_search_input: "Enter the job title",
  departments_modal_title: "Abteilungen",
  employees_in_familization: "Mitarbeiter",
  save_changes: "Änderungen speichern",
  toasts: {
    article: {
      view: {
        error: "Error",
        error_access: "Du hast keinen Zugriff"
      }
    }
  },
  familiarization_title: "Einführung in den Artikel",
  familiarization_description: "Lesen Sie den Knowledge Base-Artikel",
  link_to_article: "Link zum Artikel",
  i_familiarize: "Ich bin vertraut!",
  familiarized: "Artikel gelesen",
  undo: "rückgängig machen",
  generation_started: "Generierung gestartet",
  restart: "Neu starten",
  need_to_familiarize: "Pflichtlektüre",
  familiarize_required: "Pflichtlektüre",
  familiarize: "Machen Sie sich vertraut",
  clear_changes: "Änderungen löschen",
  accept_selected: "Auswahl bestätigen",

  departments_tree: {
    tree: {
      clear_all_text: "Alles löschen",
      clear_value_text: "Wert löschen",
      loading_text: "Laden...",
      no_children_departments: "Keine untergeordneten Abteilungen",
      no_results: "Keine Ergebnisse",
      retry_text: "Wiederholen?",
      search_prompt: "Beginnen Sie den Text zu schreiben...",
    },
  },
  article: {
    edit: {
      data_type: {
        table: "Tabelle",
        content: "Artikel",
      },
      statuses: {
        public: "Veröffentlicht",
        gray: "Entwurf",
        archive: "Archiv",
      },
      types: {
        normal: "Die Artikel",
        snippet: {
          informer: "Datenquelle",
          content: "Контент",
        },
      },
      field: {
        name: "Artikeltitel",
        content: "Artikelinhalt",
        preview: "Kurzbeschreibung",
        reason: "Grund für die Änderung des Artikels",
        section: "Übergeordneter Abschnitt",
        data_type: "Datei Format",
        table: "Excel Tabelle",
        type: "Typ",
        status: "Status",
        responsible: "Verantwortliche Mitarbeiter ",
      },
      title: {
        tags: {
          article: "Hashtags",
          snippet: {
            informer: "Datenquelletypen",
            content: "Inhaltstypen",
          },
        },
      },
    },
    title: {
      create: {
        article: "Die Artikel erstellen",
        snippet: {
          informer: "Den Datenquelle erstellen",
          content: "Den Inhalt erstellen",
        },
      },
      edit: {
        article: "Die Artikel bearbeiten",
        snippet: {
          informer: "Den Datenquelle bearbeiten",
          content: "Den Inhalt bearbeiten",
        },
      },
      favorite_articles: "Ausgewählte Artikel",
      last_update_articles: "Zuletzt geändert",
    },
    date: {
      open: "Offen inklusiv",
      close: "Inklusive geschlossen",
    },
    no_articles_categories: "Es gibt noch keine Abschnitte und Artikel in diesem Bereich",
    view: {
      errors: {
        403: "Du hast keinen Zugriff"
      }
    },
    history:{
      reason: "Grund für die Änderung",
      date: "Datum",
      editor: "Editor",
    },
  },
  snippet: {
    informer: {
      name: "Datenquellename",
      content: "Datenquelle Inhalt",
    },
    content: {
      name: "Inhaltsname",
      content: "Inhaltsfüllung",
    },
    used: "Used",
  },
  comment: {
    mention: "@ Benutzer erwähnen",
    suggestion: {
      label: "Die Änderung zur Artikel vorschlagen",
      result: {
        title: "Der Vorschlag gesendet ist!",
        message:
          "Die verantwortliche Person wird über den Vorschlag zur Änderung dieses Artikels informiert!",
        message_thanks: "Danke für den Kommentar!",
      },
    },
  },
  search: {
    articles: "All results in articles",
    contents: "All results in contents",
    informers: "All results in informers",
    sections: "All results in sections",
    nothing_found: "No results found",
    surveys: "All results in surveys",
    include_in_result: "Include in the result:",
    by_sections: "By sections",
    strict: {
      any_form: "In any form",
      as_request: "Exactly as in the request"
    },
    nothing_found_long: "Leider wurden keine Ergebnisse gefunden"
  },
  locale: {
    de: "de",
    en: "en",
    ru: "ru",
  },
  app: {
    list: "Services",
    goals: "Personal Development Plan",
    knowledge_base: "Knowledge Base",
    poll_dev: "Testing",
    poll: "Testing",
    review: "Review 360",
  },
  support: {
    header: "Contact Knowledge Base support",
    select_recipients: "Select recipients",
    placeholder: "Start typing...",
    error_required: "This field is required",
    error_length: "Maximum length of message is 1000 characters",
    add_users: "Add users",
    error_count: "Select at least 1 recipient",
  },
  glossary: {
    header: "Glossar",
    title: 'Begriff',
    description: 'Definition',
    add_term: 'Begr. hinzufügen',
    adding_term: 'Hinzufügen eines Begriffs',
    enter_term: "Geben Sie einen Begriff ein",
    enter_description: "Geben Sie eine Definition ein",
    error_termin: "Der Begriff muss mindestens 2 Zeichen enthalten",
    error_description: "Die Definition muss mindestens 10 Zeichen enthalten",
  },
  buttons: {
    ok: "OK",
    cancel: "Abbrechen",
    delete: "Löschen",
    edit: "Bearbeiten",
    actions: "Aktionen",
    back: "Back",
    save: "Speichern",
    confirm: "Bestätigen",
    continue: "Continue",
    add: "Hinzufügen",
    close: "Schlißen",
  },
  roles: {
    admin: "Administrator",
    editor: "Editor",
    user: "Nutzer",
    creator: "Schöpfer",
    not_specified: "Nicht angegeben",
  },
  workspace_users: {
    title: "Workspace users",
    table_headers: {
      name: "Name",
      last_name: "Last Name",
      email: "email",
      role: "Role",
    },


  },
  access: {
    modal: {
      title: "Zugriffrechte definieren",
    },
  },
  delete_modal: {
    delete_article: "Artikel löschen",
    delete_section: "Partition löschen",
    delete_article_question: "Sind Sie sicher, dass Sie den Artikel löschen möchten",
    delete_section_question: "Sind Sie sicher, dass Sie die Partition löschen möchten",
    delete_article_content: "Der Inhalt des Artikels, einschließlich der Bilder und Dateien, geht unwiderruflich verloren!",
    delete_section_content: "Der Inhalt der Partition, einschließlich der Bilder und Dateien, geht unwiderruflich verloren!",
  },
  trash: {
    title: "Papierkorb",
    alert: "Articles and sections can be restored from the Trash if deleted within the last 30 days. After 30 days, items in the Trash will be automatically and permanently deleted",
    articles: "Artikel",
    sections: "Abschnitte",
    headers: {
      deleted_at: "Löschdatum",
      title: "Titel"
    },
    restore_modal: {
      title: "Wiederherstellen",
      body_articles: "Wollen Sie die geprüfte Artikel wiederherstellen?",
      body_sections: "Wollen Sie die geprüfte Abschnitte wiederherstellen? Die Artikel dieser Abschnitte werden ebenfalls wiederhergestellt",
    }
  },
  "Таблица обрабатывается, перезагрузите страницу позже":
    "Die Tabelle wird verarbeitet. Laden Sie die Seite später neu",
  "Не удалось обработать таблицу": "Tabelle konnte nicht verarbeitet werden",
  "Тип файла не соответствует разрешенным к загрузке":
    "Der Dateityp stimmt nicht mit den zulässigen Uploads überein",
  "Максимальный размер файла": "maximale Dateigröße",
  "Необходимо предоставить название": "Name erforderlich",
  "Содержимое статьи не может быть пустым":
    "Der Artikelinhalt darf nicht leer sein",
  "Права применены на все подчинённые отделы":
    "Rechte gelten für alle untergeordneten Abteilungen",
  "Права не применены на все подчинённые отделы":
    "Rechte gelten nicht für alle untergeordneten Abteilungen",
  "Применить на все подчинённые демаптаменты":
    "Bewerben Sie sich bei allen untergeordneten Abteilungen",
  Подотделы: "Unterabteilungen",
  "Отдел...": "Abteilung...",
  "Применить к подотделам": "Bewerben Sie sich bei den Unterabteilungen",
  "База знаний": "Wissendatenbank",
  Разделы: "Bereiche",
  "Нет подходящих пользователей": "Keine passenden Benutzer",
  "IT-внедрения": "IT-Implementierung",
  Разработка: "Programmierung",
  "Ключ раздела": "Abschnittsschlüssel",
  "Укажите ключ": "Geben Sie einen Schlüssel ein",
  "Доступен только мне": "Nur für mich verfügbar",
  "Выберите создателя": "Wählen Sie den Ersteller",
  "Выберите категорию раздела. Можно несколько":
    "Wählen Sie eine Abschnittskategorie. Mehrere",
  "Поиск по базе": "Suche in der Datenbank",
  Поиск: "Suche",
  "Ссылка скопирована": "Kopiert",
  Поделиться: "Teilen",
  "Создать статью": "Artikel hinzufügen",
  "Создать раздел": "Bereich hinzufügen",
  "Создание статьи": "Hinzufügung des Artikels",
  "Создание раздела": "Bereich erstellen",
  "Редактирование статьи": "Artikel bearbeiten",
  "Редактирование раздела": "Bereich bearbeiten",
  "Редактировать раздел": "Bereich bearbeiten",
  Заголовок: "Titel",
  "Введите заголовок": "Geben Sie einen Titel ein",
  "Введите текст здесь": "Geben Sie hier einen Text ein",
  Название: "Name",
  Описание: "Beschreibung",
  "Настройки доступа": "Zugriffrechte definieren",
  "Настроить права доступа на редактирование":
    "Zugriffrechte für Bearbeitung definieren",
  "Выберите пользователя": "Wählen Sie einen Nutzer aus",
  "Выберите группы пользователей": "Wählen Sie eine Nutzergruppe aus",
  Расширенный: "Erweitert",
  "Восстановить эту версию": "Aktuelle Version wiederherstellen",
  "Добавить в избранное": "zu Lesezeichen hinzufügen",
  "В избранное": "Zu Lesezeichen hinzufügen",
  "Удалить из избранного": "Aus Lesezeichen löschen",
  "Ответственный за статью сотрудник отвечает за предложения по изменению статьи":
    "Die Person, die für den Artikel verantwortlich ist, ist für Vorschläge zur Änderung des Artikels verantwortlich",
  "Поисковая выдача": "Suchergebnisse",
  "Результаты поиска": "Suchergebnisse",
  "Найти статью или раздел": "Suchen Sie einen Artikel oder eine Rubrik",
  "Роли пользователей": "Nutzerrollen",
  "Написать комментарий": "Kommentar schreiben",
  "Введите сообщение": "Geben Sie eine Nachricht ein",
  "Скрыть форму": "Formular ausblenden",
  Ответить: "Antworten",
  Роль: "Rolle",
  "Назначие прав пользователя": "Benutzerrecht hinzufügen",
  "Это поле обязательное": "Dieses Feld ist erforderlich",
  "Выберите родительский раздел": "Wählen Sie einen übergeordneten Bereich aus",
  "Вернуться к редактированию": "Zurück zur Bearbeitung",
  "подсказка по созданию статьи":
    'Um einen Artikel in einem Bereich zu erstellen, klicken Sie bitte auf "Artikel erstellen" am unteren Rand der linken Seitenleiste',
  "подсказка по созданию раздела":
    'Um einen Bereich zu erstellen, klicken Sie bitte auf "Bereich erstellen" oben rechts am Bildschirm',
  "Изменения вносил": "Änderungen, wurden vorgenommen von",
  "Раздел не содержит подразделов и статей":
    "Der Abschnitt enthält keine Unterabschnitte und Artikel.",
  "Предыдущие версии статьи не найдены":
    "Keine früheren Versionen des Artikels gefunden.",
  Страниц: "Seiten",
  "Дата изменения": "Datum ändern",
  Отправить: "Senden",
  "История версий": "Versionsverlauf",
  "Ответственные за статью": "Verantwortlich für den Artikel:",
  "Введите название": "Geben Sie den Namen ein",
  "Начните вводить...": "Beginnen Sie mit der Eingabe",

  Пользователи: "Nutzer",
  "Ошибка отображения списка доступов":
    "Fehler bei der Anzeige der Zugriffrechteliste",
  "Ошибка сохранения": "Fehler beim Speichern",
  Ошибка: "Error",
  "Недостаточно прав доступа или ресурс не существует":
    "Unzureichende Zugriffsrechte oder Objekt nicht gefunden",
  "Введите текст здесь...": "Geben Sie hier einen Text ein...",
  Код: "Code",
  Зеленый: "Grün",
  Жирный: "Dicke Schrift",
  Прозрачный: "Transparent",
  Подсвеченный: "Beleuchtet",
  "С пробелами": "Abstand",
  Обрамлённый: "Umrandet",
  Заглавный: "Großbuchstaben",
  Серый: "Grau",
  "Верхний уровень": "Obere Ebene",
  "Убрать лайк": "Like entfernen",
  Нравится: "Mir gefällt",
  Тeг: "Tag",
  "Поделиться в Б24": "In Bitrix 24 teilen",
  Тeги: "Hashtag",
  "Тeг не содержит привязанный статей":
    "Das Tag enthält keine angehängten Artikel",
  Статьи: "Artikel",
  Скрипты: "Umfragen",
  Сниппеты: "Snippets",
  Информеры: "Datenquellen",
  Контенты: "Inhalte",
  "Права доступа": "Zugriffrechte",
  "Пользователь...": "Nutzer...",
  "Право доступа...": " Zugriffrecht...",
  "Создать подраздел": "Unterbereich erstellen",
  "По умолчанию": "Standardmäßig",
  "Полный доступ": "Voller Zugriff",
  "Нет доступа": "Zugriff verweigert",
  Просмотр: "Lesen",
  Редактирование: "Bearbeitung",
  "Импорт из БЗ V16": "Import aus KB V16",
  "Импорт из БЗ V16 кнопка": "Starten",
  "Импорт из БЗ V16 текст":
    " Import von Artikeln und Abschnitten aus 16 Versionen der Wissensdatenbank.",
  "Импорт из БЗ V16 текст 2":
    "ACHTUNG! Wenn etwas schief gelaufen ist, hilft das wiederholte ausführen der Synchronisierung nicht, das Problem zu lösen...",
  "Импорт из БЗ V16 запущен":
    "Import gestartet - dies kann eine Weile dauern...",

  "У вас нет доступа на добавление раздела":
    "You do not have access to create a section",
  "Статья будет опубликована в Живой ленте":
    "The article will be published in Bitrix Blog Post.",
  "Спасибо что поделились статьей": "Thank you for sharing the article!",
  "Ресурс не найден": "Ressource nicht gefunden",
  "Добавить статью": "Artikel erstellen",
  "Поиск по пользователям": "Suche nach Benutzern",
  "Добавить раздел": "Abschnitt erstellen",
  Журналирование: "Protokollierung",
  Инструменты: "Werkzeuge",
  "Панель администратора": "Verwaltung",
  Опубликовать: "Veröffentlichen",
  "Добавить информер": "Datenquelle hinzufügen",
  "Добавить контент": "Inhalt hinzufügen",
  "Закрыть доступ всем": "Zugriff für alle schließen",
  subscribe: "Update abonnieren",
  unsubscribe: "Update abbestellen",
  sorting: "Sortierung",
  sorting_description: "Je höher der Wert, desto höher die Priorität",
  sorting_range: "Von 1 bis 100",
  sorting_validation:
    "Die Sortierung muss im Wertebereich von 1 bis 100 eingestellt werden",
  enter_article_name: "Geben Sie den Titel des Artikels ein",
  additionally: "Zusätzlich",
  active_time: "Artikel aktive Zeit",
  from: "von",
  to: "zu",
  updated: "Updated",
  to_article: "Zum Artikel",
  to_section: "Zum Abschnitt",
  full_search: "Erweiterte Suche",
  select_users: "Select employees",
  departments_title: "Departments",
  positions: "Positions",
  competences: "Competences",
  employees: "Employees",
  department_search: "Search by department",
  position_search: "Search by position",
  competence_search: "Search by competence",
  employee_search: "Search by employee",
  new_articles: {
    title: "Neue Artikel",
    remove: "Alles aus neu entfernen",
    remove_single: "Von neu entfernen",
    remove_confirm:
      "Möchten Sie die markierten Artikel wirklich aus den neuen entfernen?",
  },
  favorites: {
    title: "Lesezeichen",
    is_empty: "Lesezeichenliste ist leer",
  },
  archive: {
    title: "Archiv",
    is_empty: "Archiv ist leer",
  },
  drafts: {
    title: "Entwürfe",
    is_empty: "Drafts list is empty",
  },
  journal: "Protokollierung",
  analytics:{
    header: "Analytik"
  },
  analyticsPage: {
    popularAuthors: {
      title: "Meistveröffentlichte Autoren"
    },
    articlesPerPeriod: {
      number: "Anzahl der erstellten Artikel",
      title: "Anzahl der erstellten Artikel",
      week_number: "Wochennummer",
      month_number: "Monat",
      year_number: "Jahr"
    },
    most_viewed_articles: {
      title: "Meistgesehene Artikel",
      denied_article: "Der Zugriff auf diesen Artikel wird verweigert"
    },
    authorizations_per_period: {
      number: "Anzahl der Autorisierungen",
      title: "Besucherzahl",
    }
  },
  familiarization_page: {
    manage_list: "Liste verwalten",
    title: "Zum Vorlesen"
  },
  wizard: {
    "script": {
      "one_with_title": "Umfrage {title}",
      "one": "Umfrage",
      "menu": {
        "sections": "Bereiche",
        "list": "Umfragen",
        "create": "Umfrage erstellen"
      },
      "list": {
        "Wizard": "Wizard",
        "buttons": {
          "create": "Umfrage erstellen",
          "edit": "Umfrage bearbeiten",
          "delete": "Umfrage löschen",
          "create_section": "Bereiche erstellen"
        },
        "confirm_delete_survey": "Löschen Umfrage?"
      },
      "editor": {
        "create": {
          "heading": "Umfrage erstellen",
          "publish": "Veröffentlichen",
          "error_save": "Error with saving",
          "tariff_restriction": "Your tariff does not allow to create more surveys",
          "show_tariffs": "Show tariffs"
        },
        "edit": {
          "heading": "Umfrage bearbeiten",
          "button": "Bearbeiten",
          "edit_questions": "Fragen bearbeiten",
          "save": "Speichern"
        },
        "title": "Name der Umfrage",
        "placeholder_title": "Geben Sie den Titel ein",
        "section": "Parent section",
        "placeholder_section": "Start typing...",
        "description": "Umfragebeschreibung",
        "placeholder_description": "Geben Sie eine Beschreibung ein",
        "optional": "Optional",
        "radio": "One from list",
        "checklist": "Checklist",
        "listing": "Listing",
        "root_section": "Upper level",
        "picture": "Bild"
      }
    },
    "question": {
      "editor": {
        "create_heading": "Fragen erstellen",
        "edit_heading": "Fragen ändern für: ",
        "question": "Frage",
        "new_question": "Neue Frage",
        "option": "Mögliche Antwort",
        "sorting": "Sortierung",
        "show_answer": "Zeige die Antwort",
        "to_next_question": "Zur nächsten Frage",
        "link_to_article": "Link to article",
        "link_to_section": "Link to section",
        "show_article": "To article",
        "show_section": "To section",
        "add_option": "Addiere Frage",
        "additional": "Weitere Fragen",
        "add_question": "Add question",
        "delete_question": "Frage löschen",
        "save": "Fragen speichern",
        "error_fill_fields": "Füllen Sie alle Felder aus",
        "placeholder_question": "Formulierung der Frage",
        "search": "Search in Knowledge Base"
      },
      "view": {
        "back": "Zurück",
        "forward": "Vonstatten",
        "additional": "Weitere Fragen"
      }
    },
    "section": {
      "view": {
        "edit": "Edit",
        "create": "Create subsection",
        "title": "Scripts sections",
        "back": "Back"
      },
      "editor": {
        "header_create": "Create scripts section",
        "header_edit": "Scripts section editing",
        "title": "Title",
        "placeholder_title": "Enter title",
        "section": "Parent section",
        "placeholder_section": "Start typing...",
        "description": "Description",
        "placeholder_description": "Enter description",
        "cancel": "Cancel",
        "save": "Save",
        "create": "Create scripts section",
        "error_required": "This field is required",
        "error_title": "Name should be at least 3 characters long",
        "root_section": "Upper level"
      }
    },
  }
};
