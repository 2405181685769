import Vue from "vue";
const comments = {
  namespaced: true,
  state: {
    loaded: false,
    list: {
      c0: {
        comments: [],
        loaded: true,
        page: 1
      },
    },
  },
  mutations: {
    SET_LOADED (state, { value }) {
      Vue.set(state, 'loaded', value);
    },
    SET_LOADED_STATUS(state, { articleId, status }) {
      if (!state.list["c" + articleId]) {
          state.list["c" + articleId] = state.list.c0;
      }
      //state.list["c" + articleId].loaded = status;
      Vue.set(state.list["c" + articleId], 'loaded', status);
    },
    PUSH_COMMENTS(state, {articleId, comment, unshift = false}) {
      const newObj = JSON.parse(JSON.stringify(state.list["c" + articleId]));
      if(!comment.children){
        comment.children = [];
      }
      if (unshift) {
        newObj.comments.unshift(comment);
      } else {
        newObj.comments.push(comment);
      }
      Vue.set(state.list, `c${articleId}`, newObj);
    },
    ADD_CHILD_COMMENT(state, { comment, rootId, articleId }) {
      const rootComment = state.list["c" + articleId].comments.find(
        comment => comment.id === rootId
      );
      if (rootComment) {
          rootComment.children.unshift(comment);
          //rootComment.children.push(comment);
      }
    },
    DELETE_ROOT_COMMENT(state, { entityId, commentId }) {
      state.list["c" + entityId].comments = state.list["c" + entityId].comments.filter(
          comment => comment.id !== commentId
      );
    },
    DELETE_CHILD_COMMENTS(state, { entityId, rootId, commentsId }) {
        const rootComment = state.list["c" + entityId].comments.find(
            comment => comment.id === rootId
        );
        rootComment.children = rootComment.children.filter(
            child => !commentsId.includes(child.id)
        );
    },
  },
  getters: {
    list(state) {
      return state.list;
    },
    listForArticle: state => articleId => state.list["c" + articleId] ? state.list["c" + articleId].comments : [],
    children(state) {
      return (id) => state.list["c" + id].children;
    },
    loadedById: (state) => (articleId) => {
      if(state.list["c" + articleId]) {
        return state.list["c" + articleId].loaded;
      } else {
        return false;
      }
    },
    loaded: (state) => {
      return state.loaded;
    }
  },
  actions: {
    setLoaded({ commit }, { value }){
      commit("SET_LOADED", { value: value });
    },
    init({ commit, state }, { commentsList, articleId}) {
      commit("SET_LOADED_STATUS", { articleId, status: false });
      commit("SET_LOADED", {value: false});
      let list = [];
      if (commentsList && typeof commentsList === "object") {
        Object.keys(commentsList).forEach((k) => {
          let comment = commentsList[k];
          comment.updated_at = (new Date(comment.updated_at)).toISOString();
          comment.created_at = comment.updated_at;
          comment.children = [];
          let parentId = parseInt(comment.parent_id);

          if (parentId > 0) {
            let parent = null;
            root: for(let i = 0; i < list.length; i++){
              if(list[i].id == parentId){
                parent = list[i];
                break root;
              }
              if(list[i].children){
                for(let j = 0; j < list[i].children.length; j++){
                  if(list[i].children[j].id == parentId){
                    parent = list[i];
                    break root;
                  }
                }
              }
            }

            if(parent){
              if (!parent.children){
                parent.children = [];
              }
              comment.root_id = parent.id;
              parent.children.unshift(comment);
            }
          } else {
            list.unshift(comment);
          }
        });
      }

      for(let i = 0; i < list.length; i++){
        commit("PUSH_COMMENTS", { articleId: articleId, comment: list[i] });
      }
      //list.forEach(comment => commit("PUSH_COMMENTS", { articleId: articleId, comment: comment }));
      commit("SET_LOADED_STATUS", { articleId: articleId, status: true });
      commit("SET_LOADED", {value: true});
    },
    saveComment({ commit }, { entityId, commentData }) {
      let articleId = entityId;
      const commentDataToSend = {
        commentable_type: "HiHub\\Knowledgebase\\Models\\Article",
        commentable_id: entityId.toString(),
        message: commentData.message,
        parent_id: commentData.parent_id,
        mention_users: commentData.mention_users
      };

      return this.$app.$api.article.saveComment(commentDataToSend)
        .then((response) => {
          commit(
            "structure/setArticleComment",
            { comment: response.data.data, articleId: articleId },
            { root: true }
          );
          commit("structure/updateCommentsCount", { articleId: articleId, isAddition: true}, { root: true });

          let comment = response.data.data;
          comment.root_id = commentData.root_id;
          comment.children = [];
          if (!comment.parent_id) {
              commit("PUSH_COMMENTS", {
                  articleId: articleId,
                  comment: comment,
                  unshift: true
              });
          } else {
            commit("ADD_CHILD_COMMENT", { comment, rootId: commentData.root_id, articleId: articleId });
          }
        });
    },
    deleteComment({ commit, state }, comment) {
      const childrenIds = comment.children.map(child => child.id);
      let request = this.$app.$api.article.deleteComment(comment.id);
      request.then(() => {
          if (comment.id === comment.root_id) {
              commit("DELETE_ROOT_COMMENT", {
                  entityId: comment.entityId,
                  commentId: comment.id
              });
          } else {
              commit("DELETE_CHILD_COMMENTS", {
                  entityId: comment.entityId,
                  rootId: comment.root_id,
                  commentsId: childrenIds.concat([comment.id])
              });
          }
          //define number of child comments
          let commentsNumber = 1;
          if (comment.id === comment.root_id){ 
            commentsNumber = commentsNumber + comment.children.length;
          } else { 
            let childArr = state.list["c" + comment.entityId].comments.filter(item => item.id == comment.root_id)[0].children;
            childArr.forEach(item => {
              if(item.parent_id == comment.id){
                commentsNumber++;
              }
            });
          }

          commit("structure/updateCommentsCount", { articleId: comment.entityId, isAddition: false, value: commentsNumber}, { root: true });
          commit("structure/deleteArticleComment", { articleId: comment.entityId, commentId: comment.id}, { root: true });
      });
      return request;
    },
    suggest(state, data) {
      return new Promise((resolve, reject) => {
        this.$app.$api.article.suggest(data)
          .then(resp => resolve(resp))
          .catch(err => reject(err));

      });
    }
  },
};

export default comments;
