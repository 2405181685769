/* General imports */
import Vue from "vue";
import { default as HiHub, options } from "vue-hihub-ui-core";
Vue.use(HiHub);

import VModal from 'vue-js-modal';
Vue.use(VModal);
// Some more general imports
import "./filters";
import "./assets/main.scss";

/* Init service routers */
import routers from "./router";
routers.forEach(function (router) {
  options.router.addRoute(router);
});

/* Import service menu */
import menu from "./menu";
import { SET_MENU } from "@/core/services/store/menu.module";
options.store.dispatch(SET_MENU, { code: "aside", menu });

/* Import locales */
import * as locales from "./locales/index";
for (let locale in locales) {
  options.i18n.mergeLocaleMessage(locale, locales[locale]);
}

/* Register vuex storage */
import modules from "./store/index";
for (let i in modules) {
  options.store.registerModule(modules[i].name, modules[i].module);
}
// And set general layout settings
import { OVERRIDE_INITIAL_CONFIG } from "@/core/services/store/config.module";
import AppConfig from "./services/AppConfig";
options.store.dispatch(OVERRIDE_INITIAL_CONFIG, AppConfig);

/* Set api requests */
import ApiRequests from "./Api";
import ApiRequestsCompetences from "@hihub/vue-hihub-competences/src/Api";
options.ApiService.mergeRequests(ApiRequests);
options.ApiService.mergeRequests(ApiRequestsCompetences);

new Vue({ ...options }).$mount("#app");
