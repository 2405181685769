import Vue from "vue";

const departments = {
  namespaced: true,
  state: {
    departments: {},
    tree: null,
    rootWorkspaceDepartments: null,
    rootWorkspaceDepartmentsTitle: {},
  },
  getters: {
    get(state) {
      return (id) => state.departments[id];
    },
    departmentTitle(state) {
      return (id) => state.departments[id].title;
    },
    tree(state) {
      return state.tree;
    },
    rootWorkspaceDepartmentsTitle(state) {
      return (id) => state.rootWorkspaceDepartmentsTitle[id];
    },
    rootWorkspaceDepartmentsTree(state) {
      return state.rootWorkspaceDepartments;
    },
  },
  mutations: {
    SET_DEPARTMENTS_TREE(state, tree) {
      state.tree = tree;
      const rec = (state, tree) => {
        for (const elem in tree) {
          this.$app.$set(state.departments, tree[elem].id, tree[elem]);
          if (tree[elem].children) rec(state, tree[elem].children);
        }
      };
      rec(state, tree);
    },
    SET_ROOT_WORKSPACE_DEPARTMENTS_TREE(state, tree) {
      state.rootWorkspaceDepartments = tree;
      const rec = function (state, tree) {
        for (const elem in tree) {
          Vue.set(
            state.rootWorkspaceDepartmentsTitle,
            tree[elem].id,
            tree[elem].title
          );
          if (tree[elem].children) rec(state, tree[elem].children);
        }
      };
      rec(state, tree);
    },
  },
  actions: {
    load({ state, commit }) {
      if (state.tree === null) {
        const request = this.$app.$api.user.getDepartmentsTree();
        request.then(({ data }) => {
          commit("SET_DEPARTMENTS_TREE", data);
        });
        return request;
      }
    },
    rootWorkspaceDepartmentsLoad({ commit }) {
      const request = this.$app.$api.user.getRootDepartmentsTree({
        include_current: true,
      });
      request.then((response) => {
        commit("SET_ROOT_WORKSPACE_DEPARTMENTS_TREE", response.data);
      });
      return request;
    },
  },
};

export default departments;
