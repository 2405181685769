import Vue from "vue";

const search = {
  namespaced: true,
  state: {
    full_search: {
      data: {
        article: null,
        archive: null,
        gray: null,
        section: null,
        informer: null,
        survey: null,
        content: null,
        media: null,
      },
      counters: {
        article: null,
        archive: null,
        gray: null,
        section: null,
        informer: null,
        survey: null,
        content: null,
        media: null,
      },
      loading: {
        article: false,
        archive: false,
        gray: false,
        section: false,
        informer: false,
        survey: false,
        content: false,
        media: false,
      },
    },
    active_types: [],
    currentQuery: "",
    quick_search: {
      counter: null,
      articles: [],
      sections: [],
      surveys: [],
    },
  },
  getters: {
    articles: (state) => state.full_search.data.article,
    archive: (state) => state.full_search.data.archive,
    grays: (state) => state.full_search.data.gray,
    sections: (state) => state.full_search.data.section,
    informers: (state) => state.full_search.data.informer,
    surveys: (state) => state.full_search.data.survey,
    contents: (state) => state.full_search.data.content,
    media: (state) => state.full_search.data.media,
    loading: (state) => (data_type) => {
      return state.full_search.loading[data_type];
    },
    query: (state) => state.currentQuery,
    full_search_counter: (state) => (data_type) => {
      return state.full_search.counters[data_type];
    },
    quick_search_counter: (state) => state.quick_search.counter || 0,
    quick_search_result: (state) => (data_type) => {
      return state.quick_search[data_type] !== undefined && state.quick_search[data_type] !== null ?
          state.quick_search[data_type] :  [];
    },
  },
  mutations: {
    ADD_LOADED_DATA(state, { data, type }) {
      state.full_search.data[type] = data;
    },
    UPDATE_LOADING(state, { data_type, status }) {
      Vue.set(state.full_search.loading, data_type, status);
    },
    UPDATE_QUERY(state, query) {
      state.currentQuery = query;
    },
    CLEAR_ALL_DATA(state) {
      state.full_search.data = {
        article: null,
        archive: null,
        gray: null,
        section: null,
        informer: null,
        survey: null,
        content: null,
        media: null,
      };
    },
    UPDATE_ACTIVE_TYPES(state, types) {
      state.active_types = types;
    },
    INCREASE_SEARCH_COUNTER(state, data_type = null) {
      let current = null;
      if (!data_type) {
        current = state.quick_search.counter || 0;
        Vue.set(state.quick_search, "counter", current + 1);
      } else {
        current = state.full_search.counters[data_type] || 0;
        Vue.set(state.full_search.counters, data_type, current + 1);
      }
    },
    SET_QUICK_SEARCH_RESULT(state, payload) {
      if (payload === undefined || payload === null) {
        Vue.set(state.quick_search, "articles", []);
        Vue.set(state.quick_search, "sections", []);
        Vue.set(state.quick_search, "surveys", []);
      } else {
        Object.keys(payload).forEach((data_type) => {
          Vue.set(state.quick_search, data_type, payload[data_type]);
        });
      }
    },
  },
  actions: {
    setQuery(context, query) {
      context.commit("UPDATE_QUERY", query);
    },
    loadAll(context, data) {
      context.commit("CLEAR_ALL_DATA");
      context.commit("UPDATE_ACTIVE_TYPES", data.types);
      data.types.forEach((type) => {
        context.dispatch("load", {
          query: data.query,
          type: type,
          workspace: data.workspace,
          sections: data.sections,
          strict: data.strict,
          tags: data.tags,
          page: 1,
        });
      });
    },
    load(context, data) {
      let data_type = data.type || "article";
      context.commit("UPDATE_LOADING", {
        data_type: data_type,
        status: true,
      });
      context.commit("INCREASE_SEARCH_COUNTER", data_type);
      const current_counter = context.state.full_search.counters[data_type];

      data = {
        query: data.query || context.state.currentQuery,
        type: data.type || "article",
        workspace: data.workspace,
        sections: data.sections || null,
        strict: data.strict || null,
        page: data.page || 1,
        tags: data.tags,
      };

      let method;
      switch (data.type) {
        case "article":
        case "informer":
        case "content":
        case "archive":
        case "gray":
          method = "article";
          break;
        default:
          method = data.type;
      }

      return this.$app.$api.search[method](data)
        .then((response) => {
          if (
            context.state.active_types.includes(data.type) &&
            context.getters["full_search_counter"](data_type) ===  current_counter
          ) {
            context.commit("ADD_LOADED_DATA", {
              data: response.data,
              type: data.type,
            });
            context.commit("UPDATE_LOADING", {
              data_type: data_type,
              status: false,
            });
          }
          return response.data;
        })
        .catch(() => {
          context.commit("UPDATE_LOADING", {
            data_type: data_type,
            status: false,
          });
        });
    },
    search(context, data) {
      let method;
      switch (data.type) {
        case "sections":
          method = "sectionsList";
          break;
        default:
          method = data.type;
      }

      return this.$app.$api.search[method](data).then((response) => {
        return response;
      });
    },
    async quickSearch(context, payload) {
      context.commit("SET_QUICK_SEARCH_RESULT", null);
      context.commit("INCREASE_SEARCH_COUNTER");

      const current_counter = context.state.quick_search.counter;

      if (payload.query === undefined || payload.query.length < 2) return null;

      return this.$app.$api.search.full(payload.query).then((response) => {
        if (context.getters["quick_search_counter"] === current_counter)
          context.commit("SET_QUICK_SEARCH_RESULT", response.data);

        return response;
      });
    },
  },
};

export default search;
