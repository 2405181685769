export default {
  get(id, workspaceId, entity) {
    let res = JSON.parse(
      localStorage.getItem(entity + "-" + workspaceId + "-" + id)
    );
    if (res !== null && res.cacheExpiration) {
      if (Date.now() > res.cacheExpiration) {
        this.remove(id, workspaceId, entity);
        res = null;
      }
    }
    return res;
  },
  set(id, workspaceId, entity, value) {
    const now = new Date();
    value.cacheExpiration = now.setHours(now.getHours() + 10);
    localStorage.setItem(
      entity + "-" + workspaceId + "-" + id,
      JSON.stringify(value)
    );
  },
  exists(id, workspaceId, entity) {
    const entityFromStorage = this.get(id, workspaceId, entity);
    return entityFromStorage !== null;
  },
  remove(id, workspaceId, entity) {
    localStorage.removeItem(entity + "-" + workspaceId + "-" + id);
  },
};
